@import '@/styles/_global';
.sideBtnRoot{
  .buttonPosition.btnWrapper {
    position: fixed;
    top: 50%;
    right: 0;
    z-index: 101;
    transform: translateY(-50%);
    .imgSize {
      width: toRem(44);
      height: toRem(44);
      position:relative;
      .stickCTAbtn {
        padding: 0;
        border: none;
        img {
          position: static;
          transform: none;
          width: 2.5rem;
          height: 2.5rem;
          max-width: none;
          max-height: none;
        }
        span {
          margin: 0;
        }
        &:hover{
          color: unset !important;
          border-color: unset  !important;
          background-color: transparent !important;
          box-shadow: unset  !important;
        }
      }
    }
  }
}

.imgIconSize{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: toRem(38);
  height: toRem(38);
}

@include desktop() {
  .imgSize {
    width: toRemDesktop(64);
    height: toRemDesktop(64);
  }
  .imgIconSize{
    width: toRemDesktop(60);
    height: toRemDesktop(60);
  }
}
.stickyApply {
  position: fixed;
  right: 27px;
  z-index: 9999;
  bottom: 89px;
  max-height: calc(100vh - 130px);
  max-width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
@include desktop{
  .stickyApplymob{
    display: none;
  }
}
@include mobile{
  .stickyApplymob {
        position: fixed;
        right: 12px;
        z-index: 9999;
        max-height: calc(100vh - 130px);
        max-width: 100%;
        display: flex;
        align-items: flex-end;
        gap: 10px;
        top: 195%;
  }
  .stickyApply{
    display: none;
  }
}
.stickyApplyBtn {
  padding: 10px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF3E6C;
  color: #fff;
  font-family: var(--font-raleway);;
  font-weight: 700;
  line-height: 12px;
  font-size: 10px;
  text-align: center;
  cursor: pointer;
  box-shadow: 3px -3px 10px #00000033;
  position: relative;
}

.stickyApplyBtn::before {
  content: "";
  height: 120%;
  width: 120%;
  border-radius: 50%;
  background-color: #FF3E6C;
  opacity: 0.3;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



.headerVideo {
  position: relative;
  margin-right: toRem(18);

  a {
    display: flex;
    height: 40px;
    background-color: #ffc907;
    color: #1a325d;
    align-items: center;
    padding: 0px 15px;
    border-radius: 20px;
    font-size: 13.33px;
    line-height: 13.33px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.videoTooltip {
  z-index: 111;
  position: absolute;
  background-color: #fff;
  top: 100%;
  right: 12%;
  font-size: toRem(11);
  padding: 4px 4px 6px 6px;
  box-shadow: 0px 0px 10px #00000033;
  border-radius: 5px;
  min-width: 6.30rem;
  color: #1a325d;
  line-height: 12px;
  font-weight: 700;
  font-size: toRem(10);
  font-weight: 800;
  text-align: center;
}

.videoTooltipClose {
  display: none;
}

.videoTooltipRightAlign {
  text-align: -webkit-right;
  position: relative;

}

.closeSpan {
  cursor: pointer;
  position: absolute;
  left: 88%;
}

@include mobile() {
  .headerVideo {
    margin-right: toRem(8);

    a {
      padding: 0px 10px;
    }
  }
  .videoTooltip {
    min-width: 6.6rem;
    padding: 6px 4px 6px 6px;
    
  }
}
