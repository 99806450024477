@import '@/styles/_global';
.root {
  // mt-[92px] lg:mt-[110px]
  margin-top: toRem(92);
}
.newsletterBox {
  position: relative;
  p.emailError,
  p.message {
    position: absolute;
    bottom: -25px;
    left: 10px;
    padding: 5px 0;
    font-size: toRem(10);
  }
}
.message {
  color: green;
}
.emailError {
  color: maroon;
}
.heading {
  color: #1a325d;
  font-family: var(--font-raleway);
  font-size: toRem(20);
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.subHeading {
  color: #686868;
  font-family: var(--font-raleway);
  font-size: toRem(16);
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}

.contact {
  color: #686868;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: var(--font-raleway);
  font-size: toRem(20);
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  letter-spacing: 2px;
  margin-left: 0;
  padding-left: 28px;
  background-position: -29px -530px !important;
  padding-top: 2px;
  padding-bottom: 3px;
}

.contactBox {
  margin-bottom: toRem(20);
}

.newsletterBox {
  width: 100%;
}

.newsletterInput {
  color: #585858;
  font-size: toRem(12);
  font-style: normal;
  font-weight: 500;
  line-height: toRem(16);
  letter-spacing: toRem(0.36);
  padding: toRem(17);
  padding-right: 45%;
}

.newsletterButton {
  color: #ffffff;
  border-radius: toRem(32);
  padding: toRem(10) toRem(18);
  font-family: var(--font-raleway);
  font-size: toRem(14);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: toRem(0.2);
  text-transform: uppercase;
  margin-right: toRem(6);
  cursor: pointer;
}

.newsletterButton:disabled {
  cursor: not-allowed;
  opacity: 0.8;

  // show a tooltip when the button is disabled and hovered over to enter email
  &:hover {
    &::after {
      content: "Please enter a valid email";
      position: absolute;
      top: 105%;
      left: 50%;
      transform: translateX(-50%);
      background-color: #000;
      color: #ffffff;
      padding: toRem(5) toRem(10);
      border-radius: toRem(5);
      font-size: toRem(12);
      z-index: 1;
      width: toRem(150);
    }
  }
}

.socialMedia {
  margin-top: toRem(44);
}

.copyright {
  color: #686868;
  font-family: var(--font-raleway);
  font-size: toRem(15);
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 28.5px */
  letter-spacing: 1px;
}

.logo {
  margin-top: toRem(68);
  // margin-left: toRem(24);
  img {
    max-width: toRem(200);
  }
}

.contactSponsored{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: toRem(8);
  color: #686868;
  font-weight: 600;
  font-family: var(--font-raleway);
}

@include desktop() {
  .root {
    margin-top: toRemDesktop(110);
  }
  .footerBottomSection {
    padding-left: toRemDesktop(124);
    padding-right: toRemDesktop(124);
  }
  .heading {
    font-size: toRemDesktop(20);
  }

  .subHeading {
    font-size: toRemDesktop(16);
  }

  .contact {
    font-size: toRemDesktop(20);
  }

  .contactBox {
    margin-bottom: toRemDesktop(20);
  }

  .newsletterInput {
    font-size: toRemDesktop(16);
    line-height: toRemDesktop(21);
    letter-spacing: toRemDesktop(0.48);
    padding: toRemDesktop(15) toRemDesktop(27);
    padding-right: 35%;
  }

  .newsletterButton {
    font-size: toRemDesktop(14);
    letter-spacing: toRemDesktop(0.2);
    padding: toRemDesktop(10) toRemDesktop(18);
    margin-right: toRemDesktop(6);
  }

  .newsletterButton:disabled {
    &:hover {
      &::after {
        font-size: toRemDesktop(12);
        padding: toRemDesktop(5) toRemDesktop(10);
        width: toRemDesktop(150);
      }
    }
  }

  .socialMedia {
    margin-top: toRemDesktop(55);
  }
  .copyright {
    font-size: toRemDesktop(19);
  }

  .logo {
    margin-top: toRemDesktop(81);
  }
}
