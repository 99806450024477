@import '@/styles/_global';
.headerVideo {
  position: relative;
  margin-right: toRem(18);

  a {
    display: flex;
    height: 40px;
    background-color: #ffc907;
    color: #1a325d;
    align-items: center;
    padding: 0px 15px;
    border-radius: 20px;
    font-size: 13.33px;
    line-height: 13.33px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.videoTooltip {
  z-index: 111;
  position: absolute;
  background-color: #fff;
  top: 110%;
  right: 0%;
  font-size: toRem(11);
  padding: 4px 4px 4px 6px;
  box-shadow: 0px 0px 10px #00000033;
  border-radius: 5px;
  min-width: toRem(150);
  color: #1a325d;
  line-height: 12px;
  font-weight: 700;
  font-size: toRem(10);
  font-weight: 800;
}

.videoTooltipClose {
  display: none;
}

.videoTooltipRightAlign {
  text-align: -webkit-right;
}

.closeSpan {
  cursor: pointer;
}

.videoIcon {
  width: 20px;
  display: flex;
  animation: videoIcon infinite 2s;
  transform-origin: center;
}

@keyframes videoIcon {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(-20deg);
  }
  20% {
    transform: rotate(20deg);
  }
  30% {
    transform: rotate(-20deg);
  }
  40% {
    transform: rotate(20deg);
  }
  50%,
  100% {
    transform: rotate(0deg);
  }
}

@include mobile() {
  .headerVideo {
    margin-right: toRem(8);

    a {
      padding: 0px 10px;
    }
  }
  .videoTooltip {
    min-width: toRem(125);
    padding: 6px 4px 4px 6px;
  }
}
