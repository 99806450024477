@import '@/styles/_global';
.fixedHeader {
  position: sticky;
  z-index: 1010;
  transition: top 0.3s ease-in-out;
  top: 0;
}

.searchIconNew{
  background-repeat: no-repeat;
  background-position: -7px -895px !important;
  background-size: 50px;
  background-image: url("../../../../assets/icons/header-footer-sprite.svg");
}

.login{
  border: 2px solid #fff;
  padding: toRem(5) toRem(12) toRem(5) toRem(12);
  margin-right: toRem(14);
  border-radius: toRem(32);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: toRem(6);  
  font-weight: 700;
  font-size: 11px;
  font-family: var(--font-raleway);

  &:hover{
    color: #1a325d;
    background: #fff;
  
    .svgHover{
      stroke: #1a325d;
    }
  }
}

.studentLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 999px;
  border: 1.5px solid #1a325d;
  padding: 0.6rem;
  margin-bottom: toRem(6);
  font-family: var(--font-raleway);
  font-weight: 600;
}

.callUs{
  padding: 0.5rem 1.1333333333rem;
  color: #1a325d;
  border-color: #1a325d;
  background: #ffc907;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: 1.5px solid #1a325d;
  font-weight: 700;
  font-size : 14px;
  font-family: var(--font-raleway);
  margin-right: toRem(12);

}

.callUsSvg {
  margin-right: 6px;
}

@include mobile() {
  .searchIconNew{
    background-position: -6px -750px !important;
  }
  .callUs{
    padding: 0.5rem 1.1333333333rem;
    color: #1a325d;
    border-color: #1a325d;
    background: #ffc907;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    border: 1.5px solid #1a325d;
    font-weight: 600;
    font-family: var(--font-raleway);
  }

  .studentLogin{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 999px;
    border: 1.5px solid #1a325d;
    padding: 0.4rem 0.4rem 0.4rem 0.5rem;
    font-family: var(--font-raleway);
    font-weight: 500;
  }  

}

.callImg{
  margin-right: toRem(3);
}


.callUsBtn {
  img {
    height: 26px !important;
    width: 14px !important;
  }
  >span{
    display: flex;
    }
    span {
      font-weight: 700 !important;
      text-transform: none !important;
      font-family: Raleway, sans-serif !important;
  }
}

.containerRadius {
  border-radius: toRem(16);
}

.searchBoxContainer {
  input:focus {
    outline: none;
  }
}

.mobileMenu {
  border-radius: 16px;
  background: #fff;
  box-shadow: 5px 5px 50px 0px rgba(199, 199, 199, 0.35);
  color: #1a325d;
  height: calc(100% - 20px);
  overflow-y: auto;
  padding: toRem(4);
  overflow-y: auto;
}

.menuItem {
  font-family: var(--font-raleway);
  font-size: toRem(16);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  padding: 0 toRem(14) 0 0;
  > a > span {
    white-space: nowrap;
  }

 
}

.mr {
  margin-right: toRemDesktop(20);
  margin-bottom: toRem(6);
}

.searchBoxContainerMobile {
  display: block;
}

.desktopMenu {
  display: none;
}

.mobileMenu {
  .menuItem {
    padding: toRem(25) toRem(20);
    border-bottom: 1px solid rgba(#e7e7e7, 0.5);
  }

  > div {
    width: 100%;
  }
  .searchBox {
    width: 100%;
  }
  input {
    border-radius: 12px;
    background: rgba(231, 231, 231, 0.4);
    height: toRem(45);
    min-width: 100%;
    padding-left: toRem(40);
    color: #000;
  }

  .searchIcon {
    width: 17px;
    height: 16px;
    top: 15px;
    left: 15px;
  }
}

.searchImage {
  height: toRem(34);
  width: toRem(34);
  margin-right: toRem(18);
}

.results {
  z-index: 100;
  width: 100%;
  div {
    border-bottom: 1.5px solid rgba(#1a325d, 0.2);
    &:last-child {
      border-bottom: none;
    }
  }
}

.resultsItem {
  color: #1a325d;
  font-family: var(--font-raleway);
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
  letter-spacing: 1px;
  font-size: toRem(16);
  padding: toRem(20) 0;
}

.linkBtn {
  button {
    display: flex;
    padding: toRem(16) toRem(18);
    border-radius: 32px;
    margin-bottom: toRem(10);
    border: 1px solid #1a325d;
    font-size: toRem(16);
  }

  a {
    display: flex;
    padding: toRem(16) toRem(18);
    border-radius: 32px;
    margin-bottom: toRem(10);
    border: 1px solid #1a325d;
    font-size: toRem(16);
  }
  &.linkBtnYellow {
    button {
      background-color: #ffc907;
      border: none;
    }
  }
}

.submenuArrow {
  width: 12px;
  transform: rotate(-90deg);
}

.searchClose {
  height: toRem(18);
  width: toRem(18);
  right: toRem(10);
  top: toRem(12);
}

.logo {
  padding: toRem(20) 0;
  padding-left: toRem(22);
  img {
    height: toRem(40);
    width: toRem(114);
  }
}

.mobileContainer {
  position: absolute;
  height: 87%;
  width: 100%;
  left: 0;
  right: 0;
  top: 100px;
  z-index: 110;
  overflow-y: auto;
  background-color: #fff;
  position: fixed;
}

.callTitle {
  font-family: Raleway, sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.studentLoginMobile{
  background-position: 123px -1221px;
  padding-right: 30px !important;
}
.searchIconNew{
  width: 60px;
  height: 60px;
  background-position: -10px -1303px;
}

.searchClose{
  background-position: -18px -716px;
  background-size: 50px;
}
.callLink{
  background-position: -21px -1206px;
}


@media (min-width: 1199px) {
  .searchIconNew{
    background-position: -7px -895px;
    background-size: 50px;
  }
  .searchClose{
    background-position: -13px -486px;
    background-size: 34px;
  }
  .callLink{
    background-position: -25px -1471px;
  }
}


@media (min-width: 1600px) {
  .searchIconNew{
    background-position: -14px -1310px;
    background-size: inherit;
  }
  .searchClose{
    background-position:-15px -572px;
    background-size: 40px;
  }
  .callLink{
    background-position: -27px -1469px;
  }
}

@media (min-width: 1799px) {
  .searchIconNew{
    background-position: -10px -1304px !important;
    background-size: inherit;
  }
  .callLink{
    background-position: -27px -1467px;
  }
}
@media (max-width: 1199px) {
  .searchIconNew{
    width: 36px;
    height: 36px;
    background-position: -6px -750px;
    background-size: 42px;
  }
}

@media (max-width: 375px) {
  .logo {
    max-width: 7.125rem;
  }
}

@include desktop() {
  .results {
    position: absolute;
  }
  .mobileContainer {
    display: none;
  }
  .menuText {
    font-size: toRemDesktop(16);
    position: relative;

    &:hover::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      border-bottom: 6.5px solid #ffc907;
      border-radius: 2px;
      bottom: calc(-100% - toRemDesktop(26));
      left: 0;
      z-index: 0;
    }
  }

  .openMenu::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    border-bottom: 6.5px solid #ffc907;
    border-radius: 2px;
    bottom: calc(-100% - toRemDesktop(26));
    left: 0;
    z-index: 0;
  }

  .menuItem {
    padding: 0 toRemDesktop(18) 0 0;
  }

  .fixedHeader {
    top: 0;
  }

  .containerRadius {
    border-radius: toRemDesktop(32);
    display: flex;
    justify-content: space-between;
    padding-right: toRem(20);
  }

  .mr {
    margin-right: toRemDesktop(20);
  }

  .menuContainer {
    padding: toRemDesktop(26) 0;
  }
  .searchClose {
    height: toRemDesktop(20);
    width: toRemDesktop(20);
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .logo {
    padding: 0;
    padding-left: toRemDesktop(40);
    img {
      height: toRemDesktop(66);
      width: toRemDesktop(186);
    }
  }

  .linkBtn {
    button {
      font-size: toRemDesktop(20);
    }
  }

  .programMenu {
    top: toRemDesktop(146.4);
    z-index: 110;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .searchIcon {
    border-radius: 32px;
    z-index: 100;
    max-height: toRemDesktop(60);
    max-width: toRemDesktop(60);
  }

  .searchBox {
    padding-right: toRemDesktop(20);
    input {
      max-width: toRemDesktop(324);
      background-color: #304a79;
      height: toRemDesktop(60);
      min-width: toRemDesktop(300);
      border-radius: 32px;
      color: #fff;
      padding-left: toRemDesktop(90);
      padding-right: 2rem;
    }
  }

  .resultsContainer {
    max-height: toRemDesktop(300);
    min-height: toRemDesktop(400);
    position: absolute;
    z-index: 100;
    width: 100%;
    border-radius: toRemDesktop(30);
    overflow: hidden;
  }

  .results {
    max-height: toRemDesktop(300);
    min-height: toRemDesktop(400);
    padding: toRemDesktop(32);
  }

  .resultsItem {
    font-size: toRemDesktop(18);
    padding-bottom: toRemDesktop(16);
    &:not(:first-child) {
      padding-top: toRemDesktop(16);
    }
  }

  .desktopMenu {
    display: block;
  }

  .mobileMenu {
    display: none;
  }

  .searchBox {
    display: block;
  }

  .cta {
    font-size: toRemDesktop(20);
  }

  .searchBoxContainerMobile {
    display: none;
  }

  .callTitle{
    margin-right: 0.75rem;
    color: currentColor;
    text-align: center;
    font-size: 0.8333333333rem;
    font-style: normal;
    font-weight: 600;
  }
}
