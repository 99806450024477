@import '@/styles/_global';
.readmoreCTA {
  position: relative;
  a {
    background: #fff;
    position: relative;
  }

  button {
    font-size: toRem(16);
    letter-spacing: toRem(0.5);
    position: relative;
    background-color: white !important;
    span {
      font-weight: 500;
    }
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    border-bottom: 1px solid #d4d4d4;
    top: 50%;
    left: 0;
    z-index: 0;
    // transform: translateY(-50%);
  }
}

.hiddenFooterLinks {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.showFooterLinks {
  max-height: 500vh;
}

.subMenuItem {
  color: #686868;
  font-family: var(--font-raleway);
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  font-size: toRem(16);
  margin: toRem(7) 0;
}

.hiddenMenuContainer {
  padding-top: toRem(50);
}

.headingLink {
  color: #1a325d;
  font-family: var(--font-raleway);
  font-size: toRem(20);
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  margin-bottom: toRem(15);
}

.headingLink {
  margin: toRem(15) 0 0;
}
.showMoreArrow{
  background-position: 66px -158px !important;
  padding-right: 32px !important;
  &:hover{
    background-position: 66px -92px !important;
  }
}
.showLessArrow{
  background-position: 92px -288px !important;
  padding-right: 32px !important;
  &:hover{
    background-position: 63px -225px !important;
  }
}

@media (min-width: 1200px) {
  .showMoreArrow{
    background-position: 100px -116px !important;
    padding-right: 25px !important;
    background-size: 56px !important;
    &:hover{
      background-position: 100px -65px !important;
    }
  }
  .showLessArrow{
    background-position: 92px -220px !important;
    padding-right: 25px !important;
    background-size: 56px !important;
    &:hover{
      background-position: 92px -169px !important;
    }
  }
}

@media (min-width: 1600px) {
  .showMoreArrow{
    background-position: 110px -153px !important;
    padding-right: 38px !important;
    background-size: inherit !important;
    &:hover{
      background-position: 110px -86px !important;
    }
  }
  .showLessArrow{
    background-position: 102px -288px !important;
    padding-right: 38px !important;
    background-size: inherit !important;
    &:hover{
      background-position: 102px -221px !important;
    }
  }
}

@media (min-width: 1920px) {
  .showMoreArrow{
    background-position: 156px -149px !important;
    padding-right: 38px !important;
    background-size: inherit !important;
    &:hover{
      background-position: 156px -149px !important;
    }
  }
  .showLessArrow{
    background-position: 148px -283px !important;
    padding-right: 38px !important;
    background-size:inherit !important;
    &:hover{
      background-position: 148px -283px !important;
    }
  }
}

@include desktop() {
  .readmoreCTA {
    button {
      font-size: toRemDesktop(20);
      letter-spacing: toRemDesktop(1);
    }
  }
  .subMenuItem {
    font-size: toRemDesktop(16);
    margin: toRemDesktop(7) 0;
  }

  .hiddenMenuContainer {
    padding-top: toRemDesktop(50);
    padding-left: toRemDesktop(124);
  }

  .headingLink {
    font-size: toRemDesktop(20);
    margin: 0 0 toRemDesktop(15);
  }

  .headingLinkWithChild {
    padding: toRemDesktop(40) 0 0;
  }

  .menu1 {
    width: 70%;
    border-right: 1.5px solid #d4d4d4;
    margin-right: toRemDesktop(100);
  }
}
