@import '@/styles/_global';
.root {
  z-index: 101;
  content: "";
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: toRem(123);
  background: linear-gradient(0deg, #fff 19.79%, rgba(255, 255, 255, 0) 100%);
}

.scrollButton {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: toRem(50);
  margin: 0 toRem(12) toRem(30) toRem(12);
  border: 1px solid black;
  color: #1a325d;
  border-color: #1a325d;
  background: #ffc907;
  border-radius: 999px;
}

.show {
  display: block;
}
